/* eslint-disable promise/always-return */

/* eslint-disable unicorn/no-useless-undefined */

/* eslint-disable unicorn/consistent-function-scoping */
import { FieldState, FieldValidator } from 'final-form'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'

import validations from '../api/validations'
import Icon from '../components/Icon/Icon'
import HttpError from '../errors/HttpError'
import { StyledErrorMessage } from '../routes/Portal/UserProfile/UserProfile.style'

export const wrapWithStyledError = (errorMessage: string) => {
  return (
    <StyledErrorMessage>
      <Icon name="warning" size="medium" color="bf-red" />
      {errorMessage}
    </StyledErrorMessage>
  )
}

const useValidators = () => {
  const { t: translate } = useTranslation()

  const timeout = useRef<NodeJS.Timeout | undefined>()
  const lastValue = useRef<string | undefined>()
  const lastResult = useRef<string | undefined>()

  const required = (value?: string) =>
    value ? undefined : wrapWithStyledError(translate('microcopies.form-error-required-field'))

  const isEmail = (value?: string) =>
    value?.match(/.+@.+\..+/i)
      ? undefined
      : wrapWithStyledError(translate('microcopies.form-error-invalid-email'))

  const isEmailTaken: FieldValidator<string> = async (
    value: string,
    _allValues?: object,
    _meta?: FieldState<string>
  ) =>
    new Promise(resolve => {
      if (timeout.current) {
        clearTimeout(timeout.current)
      }

      if (value !== lastValue.current) {
        const timeoutId = setTimeout(() => {
          lastValue.current = value
          validations
            .validateEmail(value)
            .then(_response => {
              lastResult.current = undefined

              resolve(undefined)
            })
            .catch((error: unknown) => {
              const err = error as HttpError

              lastResult.current = err.message

              resolve(wrapWithStyledError(err.message))
            })
        }, 400)

        timeout.current = timeoutId
      } else {
        resolve(lastResult.current)
      }
    })

  const isPhoneNumber = (value: string | undefined) =>
    value?.match(/^\+\d+$/) || value === undefined
      ? undefined
      : wrapWithStyledError(translate('account-creation.form-invalid-phone'))

  const isBusinessId = (value: string | undefined) =>
    value?.match(/^(\d{7}-\d)$/)
      ? undefined
      : wrapWithStyledError(translate('account-creation.form-invalid-businessid'))

  const isSecurePassword = (value?: string) => {
    const pwdErrors = []

    if (value) {
      if (value.length < 16) pwdErrors.push(translate('account-creation.form-password-length'))

      if (!/.*[a-z].*[A-Z]|[A-Z].*[a-z].*/.test(value))
        pwdErrors.push(translate('account-creation.form-password-letters'))

      if (!/.*\d.*/.test(value)) pwdErrors.push(translate('account-creation.form-password-digits'))

      if (!/.*[\W_]+.*/.test(value))
        pwdErrors.push(translate('account-creation.form-password-characters'))
    }

    if (pwdErrors.length === 0) return

    return (
      <ul>
        {pwdErrors.map(err => (
          <li key={err}>{wrapWithStyledError(err)}</li>
        ))}
      </ul>
    )
  }

  return { required, isEmail, isEmailTaken, isPhoneNumber, isSecurePassword, isBusinessId }
}

export default useValidators
