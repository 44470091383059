import HttpError from '../errors/HttpError'

const validateEmail = async (email: string) => {
  const response = await fetch('/api/validations/email', {
    method: 'POST',
    body: JSON.stringify({
      email,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  })

  const data = await response.json()

  if (response.status === 409) throw new HttpError(409, data.details?.email)

  if (response.status === 422) throw new HttpError(422, data.errors[0].message)

  if (response.status === 429) throw new HttpError(429, data.message)

  return data.details.email
}

export default {
  validateEmail,
}
