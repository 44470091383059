/* eslint-disable react/jsx-no-bind */

/* eslint-disable react/iframe-missing-sandbox */

/* eslint-disable padding-line-between-statements */
import { useMutation, useQuery } from '@tanstack/react-query'
import { FunctionComponent, useEffect, useRef, useState } from 'react'
import { Field } from 'react-final-form'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate } from 'react-router'

import tokens from '../../../../api/tokens'
import users from '../../../../api/users'
import Button from '../../../../components/Button'
import Input from '../../../../components/Input'
import Link from '../../../../components/Link/Link'
import { openSnackbar } from '../../../../components/Snackbar/StoreInterface'
import { QUERY_KEY_TOKEN_USER_INFO } from '../../../../constants'
import EmailVerificationModal from '../../../../containers/EmailVerificationModal'
import FormContainer from '../../../../containers/FormContainer'
import useReturnButton from '../../../../hooks/useReturnButton'
import useValidators, { wrapWithStyledError } from '../../../../hooks/useValidators'
import { setSpinner } from '../../../../redux/slices/spinnerSlice'
import { RootState } from '../../../../redux/store'
import { FormErrors, FormValues } from '../../../../shared/types'
import handleLogout from '../../../../utils/logout-utils'
import { hasThreeConsecutive, validateServiceReturnUrl } from '../../../../utils/validation-utils'
import { StyledFormContainer, StyledTitle4 } from '../../Register.style'
import {
  ButtonContainer,
  StyledContainer,
  StyledParagraph16,
  StyledTitle5,
} from './RegistrationForm.style'

interface IRegistrationForm {
  handleNext: () => void
}

const RegistrationForm: FunctionComponent<IRegistrationForm> = ({ handleNext }) => {
  const { t } = useTranslation()
  const { required, isEmail, isPhoneNumber, isSecurePassword, isEmailTaken } = useValidators()
  const [externalValidationErrors, setExternalValidationErrors] = useState()
  const [userEmail, setUserEmail] = useState('')
  const [emailError, setEmailError] = useState()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [verificationKey, setVerificationKey] = useState<string | undefined>()
  const [sendButtonDisabled, setSendButtonDisabled] = useState(false)
  const returnButton = useReturnButton()

  const dispatch = useDispatch()

  const errorRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    if (errorRef.current && externalValidationErrors) {
      errorRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
      errorRef.current.focus()
    }
  }, [externalValidationErrors])

  // eslint-disable-next-line react-redux/useSelector-prefer-selectors
  const hasAlreadyRegistered = useSelector((state: RootState) => state.me.uid)

  const userInfoQuery = useQuery([QUERY_KEY_TOKEN_USER_INFO], tokens.getUserInfo)
  const createUserMutation = useMutation({
    mutationFn: (values: FormValues) => users.createUser(values),
  })

  useEffect(() => {
    if (createUserMutation.status === 'loading') {
      dispatch(setSpinner({ visible: true }))
    }
    if (createUserMutation.isError) {
      dispatch(setSpinner({ visible: false }))
      dispatch(openSnackbar(t('snackbar.user-creation-failed'), true))
      setSendButtonDisabled(false)
    } else if (createUserMutation.status === 'success' && createUserMutation.data.errors) {
      dispatch(setSpinner({ visible: false }))
      setSendButtonDisabled(false)
      return setExternalValidationErrors(createUserMutation.data.error)
    } else if (
      createUserMutation.status === 'success' &&
      !createUserMutation.data.errors &&
      !createUserMutation.data.error
    ) {
      dispatch(setSpinner({ visible: false }))
      handleNext()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createUserMutation.data, createUserMutation.status])

  if (userInfoQuery.status === 'loading') return <div>Loading</div>

  if (userInfoQuery.status === 'error') return <div>Error</div>

  const nameHelptext = t('account-creation.form-suomifi')

  const onSubmit = async (values: FormValues) => {
    setSendButtonDisabled(true)
    try {
      createUserMutation.mutate(values)
    } catch (error) {
      console.log(error)
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeEmail = async (event: any) => {
    setUserEmail(event.target.value)
    const emailValidation = await isEmailTaken(event.target.value)
    setEmailError(emailValidation)
  }

  const handleOnAfterEmailVerification = (vKey: string) => {
    dispatch(setSpinner({ visible: false }))
    setVerificationKey(vKey)
    setIsModalOpen(false)
  }

  const handleReturnButton = async () => {
    if (returnButton) {
      try {
        const validatedReturnUrl = await validateServiceReturnUrl(
          returnButton.service,
          returnButton.href
        )
        await handleLogout(validatedReturnUrl)
      } catch (error) {
        console.error(error)
      }
    } else {
      await handleLogout('/')
    }
  }

  window.addEventListener(
    'message',
    event => {
      event.stopImmediatePropagation()
      if (
        event.origin === `${window.location.protocol}//${window.location.host}` &&
        event.data === 'success'
      ) {
        handleNext()
      }
    },
    false
  )

  let firstname = userInfoQuery.data.value.firstname ? userInfoQuery.data.value.firstname[0] : ''
  let surname = userInfoQuery.data.value.sn ? userInfoQuery.data.value.sn[0] : ''
  const ssn = userInfoQuery.data.value.hetu ? userInfoQuery.data.value.hetu[0] : ''

  firstname = firstname.replace(/\d+/g, '')
  surname = surname.replace(/\d+/g, '')

  if (hasAlreadyRegistered) return <Navigate to="/portal" />

  return (
    <>
      <StyledTitle4>{t('account-creation.form-title')}</StyledTitle4>
      <StyledParagraph16>
        {t('account-creation.form-body1')}
        <br />
        <br />
        {t('account-creation.form-body2')}
        {t('account-creation.form-body3')}
      </StyledParagraph16>
      <StyledFormContainer>
        <FormContainer
          errorRef={errorRef}
          onSubmit={onSubmit}
          validator={(values: FormValues) => {
            const errors: FormErrors = {}

            if (values['confirm'] !== values['password']) {
              errors['confirm'] = wrapWithStyledError(t('account-creation.form-passwordsmustmatch'))
            }

            if (!values['terms']) {
              errors['terms'] = 'Must accept terms'
            }

            if (userEmail && hasThreeConsecutive(String(values['password']), userEmail)) {
              errors['password'] = wrapWithStyledError(t('microcopies.form-password-consecutive'))
            }

            return errors
          }}
          externalValidationErrors={externalValidationErrors}
        >
          <FormContainer.Field
            type="hidden"
            label={t('account-creation.form-firstname')}
            name="firstname"
            value={firstname}
            isRequired
          />
          <FormContainer.Field
            type="text"
            label={t('account-creation.form-firstname')}
            name="preferredFirstname"
            value={firstname}
            helpText={nameHelptext}
            isRequired
          />
          <FormContainer.Field
            type="hidden"
            label={t('account-creation.form-lastname')}
            name="surname"
            value={surname}
            isRequired
          />
          <FormContainer.Field
            type="text"
            label={t('account-creation.form-lastname')}
            name="preferredSurname"
            value={surname}
            helpText={nameHelptext}
            isRequired
          />
          {ssn ? (
            <FormContainer.Field
              type="text"
              label={t('account-creation.form-ssn')}
              name="ssn"
              value={ssn}
              readOnly={true}
            />
          ) : null}

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
            }}
          >
            <Input
              type="email"
              name="emailVerification"
              label={`${t('account-creation.form-email')} *`}
              onChange={onChangeEmail}
              hasError={!!emailError}
              errorMessage={emailError}
              readOnly={!!verificationKey}
              helpText={
                !verificationKey
                  ? t('account-creation.email-unverified')
                  : t('account-creation.email-verified')
              }
            />
            {!verificationKey && (
              <Button
                label={t('account-creation.verify-email-button')}
                disabled={!!emailError || userEmail === '' || !!verificationKey}
                onClick={() => setIsModalOpen(true)}
                variant="secondary"
              />
            )}
          </div>

          <Field
            name="email"
            type="hidden"
            initialValue={userEmail}
            validators={[required, isEmail]}
          >
            {({ input }) => <input {...input} value={userEmail} />}
          </Field>

          <FormContainer.Field
            label="verificationKey"
            type="hidden"
            name="verificationKey"
            isRequired
            value={verificationKey}
          />

          <FormContainer.Field
            label={t('account-creation.form-phone')}
            type="tel"
            name="tel"
            validators={[required, isPhoneNumber]}
            helpText={t('account-creation.form-phone-helptext') || undefined}
            isRequired
          />
          <FormContainer.Field
            label={t('account-creation.form-password')}
            type="password"
            name="password"
            validators={[isSecurePassword]}
            helpText={t('account-creation.form-password-specification') || undefined}
            isRequired
          />
          <FormContainer.Field
            label={t('account-creation.form-confirm-password')}
            type="password"
            name="confirm"
            isRequired
          />

          <StyledContainer>
            <StyledTitle5 style={{ marginBottom: '1rem' }}>
              {t('terms-and-conditions.accept-terms')}
            </StyledTitle5>
            <StyledParagraph16>
              <Trans
                i18nKey="terms-and-conditions.terms-description"
                t={t}
                components={{
                  link1: <Link href={`${t('terms-and-conditions.link')}`}>yleisiin</Link>,
                }}
              />
            </StyledParagraph16>
            <FormContainer.Field
              type="checkbox"
              label={t('terms-and-conditions.bf-terms')}
              name="terms"
              helpText={t('account-creation.form-suomifi') || undefined}
              isRequired
            />
          </StyledContainer>

          <ButtonContainer>
            <Button
              label={t('account-creation.form-button-abort')}
              onClick={handleReturnButton}
              variant="secondary"
            />
            <Button
              type="submit"
              label={t('account-creation.form-button-next')}
              disabled={!!sendButtonDisabled || !verificationKey}
            />
          </ButtonContainer>
        </FormContainer>

        <EmailVerificationModal
          email={userEmail}
          isOpen={isModalOpen}
          onAfterSubmit={handleOnAfterEmailVerification}
          onCancel={() => setIsModalOpen(false)}
        />
      </StyledFormContainer>
    </>
  )
}

export default RegistrationForm
