import { FieldValidator } from 'final-form'
import { FunctionComponent, useMemo } from 'react'
import { Field } from 'react-final-form'

import Checkbox from '../../../components/Checkbox'
import Input from '../../../components/Input'
import Radio from '../../../components/Radio'
import Select from '../../../components/Select'
import WithField from '../../../hocs/withField'
import useValidators from '../../../hooks/useValidators'
import { composeValidators } from '../../../utils/form-utils'
import IFieldContainer from './FieldContainer.interface'

const FieldContainer: FunctionComponent<IFieldContainer> = ({
  type,
  label,
  name,
  value,
  isRequired,
  disabled,
  validators,
  placeholder,
  helpText,
  width,
  readOnly,
  children,
  checked,
}) => {
  const { required, isEmail } = useValidators()

  // TODO: Move to HOC
  const composedValidators = useMemo(() => {
    const fieldValidators: FieldValidator<string>[] = []

    if (isRequired) fieldValidators.push(required)

    if (type === 'email') fieldValidators.push(isEmail)

    if (validators) fieldValidators.push(...validators)

    return composeValidators(...fieldValidators)
  }, [isEmail, isRequired, required, type, validators])

  const fieldLabel = isRequired ? `${label} * ` : label

  if (type === 'hidden') {
    return (
      <Field name={name} validate={composedValidators} type={type} initialValue={value}>
        {({ input }) => <input type={type} {...input} readOnly={readOnly} />}
      </Field>
    )
  }

  /**
   * Checkbox
   */
  if (type === 'checkbox') {
    const CheckboxWithField = WithField(Checkbox)

    return (
      <CheckboxWithField
        name={name}
        value={value}
        validate={composedValidators}
        label={fieldLabel}
        isRequired={isRequired}
        type={type}
        readOnly={readOnly}
        checked={checked}
      />
    )
  }

  /**
   * Radio
   */
  if (type === 'radio') {
    const CheckboxWithField = WithField(Radio)

    return (
      <CheckboxWithField
        name={name}
        value={value}
        validate={composedValidators}
        label={fieldLabel}
        isRequired={isRequired}
        type={type}
        readOnly={readOnly}
        checked={checked}
      />
    )
  }

  /**
   * Select
   */
  if (type === 'select') {
    const SelectWithField = WithField(Select)

    return (
      <SelectWithField
        name={name}
        value={value}
        validate={composedValidators}
        label={fieldLabel}
        isRequired={isRequired}
        type={type}
        width={width}
        readOnly={readOnly}
      >
        {children}
      </SelectWithField>
    )
  }

  /**
   * Input
   */
  const InputWithField = WithField(Input)

  return (
    <InputWithField
      type={type}
      value={value}
      validate={composedValidators}
      label={fieldLabel}
      name={name}
      isRequired={isRequired}
      disabled={disabled}
      placeholder={placeholder}
      helpText={helpText}
      readOnly={readOnly}
    />
  )
}

export default FieldContainer
