import { BASE_URL } from '../constants'

export const validateFinnishBusinessID = (businessID: string) => {
  const pattern = /^(\d{7}-\d)$/

  return pattern.test(businessID)
}

export const hasThreeConsecutive = (password: string, email: string) => {
  for (let i = 0; i < password.length - 2; i++) {
    if (email.includes(password.slice(i, i + 3))) {
      return true
    }
  }

  return false
}

/**
 * Handles validation of service return url and navigation
 */
export const validateServiceReturnUrl = async (service: string, href: string) => {
  try {
    const validationEndpoint = new URL(`/api/services/${service}/return`, BASE_URL)

    const returnUrl = new URL(href)

    validationEndpoint.searchParams.append('returnUrl', returnUrl.toString())

    const res = await fetch(validationEndpoint.toString())
    const data = await res.json()

    if (data.returnUrl) return data.returnUrl
  } catch (error) {
    console.error(error)
  }
}
