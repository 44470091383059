import { ComponentType } from 'react'
import { Field } from 'react-final-form'

function WithField<T>(WrappedComponent: ComponentType<T>) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function WithFieldComponent({ name, type, validate, ...props }: any) {
    return (
      <Field name={name} validate={validate} type={type} initialValue={props.value}>
        {({ input, meta }) => (
          <WrappedComponent
            {...props}
            {...input}
            meta={meta}
            hasError={!!meta.error && !!meta.touched}
            errorMessage={meta.error}
          />
        )}
      </Field>
    )
  }
}

export default WithField
